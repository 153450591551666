export default {
  appName: 'Doclivi',
  appKey: 'doclivi_admin',
  appLogo: 'https://www.toocamp.com/images/superdeal.png',
  display: {
    primaryColor: '',
    backgroundImage: 'https://source.unsplash.com/random/1920x1080',
    appLogo: 'https://www.toocamp.com/images/superdeal.png',
  },
  titleLogo: '',
  env: 'development', // production / test
  defaultLocale: 'fr',
  /* eslint-disable */
  apiUrl: process.env.VUE_APP_API_URL || '/',
  stripeKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || '/',
  buildDate: process.env.BUILDDATE || 'now',
  version: '',
  defaultTitle: 'Doclivi',
  primaryKey: 'id', // || '_id'
  features: {
    googleAuth: false,
    facebookAuth: false,
    register: true,
    passwordReset: true,
    autoWireAllModels: false,
  },
};

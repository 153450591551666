import $ from 'jquery';

// vue and vue components
import Vue from 'vue';
import Vuex from 'vuex';
import VeeValidate from 'vee-validate';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import CripVueLoading from 'crip-vue-loading';
import CoreuiVue from '@coreui/vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import VueMq from 'vue-mq';
import VueTour from 'vue-tour';
import VueMatomo from 'vue-matomo';
import VueCompositionAPI from '@vue/composition-api';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import Vue2TouchEvents from 'vue2-touch-events';

import Notify from 'vue-notifyjs';

import en from 'vue-aw-components/src/translations/en.json';

// External libraries
import qs from 'qs';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import _ from 'lodash';

import 'chartist-plugin-pointlabels';

import 'popper.js/dist/popper.min';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// router setup and local elements setup
import config from './config';
import store from './stores';
import GlobalDirectives from './globalDirectives';
import MatomoService from './services/MatomoService';

import * as filters from './filters/filters';
import App from './App.vue';

import { iconsSet as icons } from './assets/icons/icons';
import './registerServiceWorker';

// plugin setup
Vue.use(VeeValidate, {
  inject: false,
});
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueCompositionAPI);
Vue.use(Notify, {
  type: 'primary',
  timeout: 13000,
  horizontalAlign: 'right',
  verticalAlign: 'top',
});
Vue.use(VueI18n);
Vue.use(CoreuiVue);
Vue.use(GlobalDirectives);
Vue.use(VueTour);
Vue.use(Vue2TouchEvents);

Vue.use(require('vue-chartist'));

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
});

// global variables
window.App = {
  locale: config.defaultLocale || 'fr',
};
window.$ = $;
window.jQuery = $;
window._ = _;
window.moment = moment;

async function registerListeners() {
  if (Capacitor.isPluginAvailable('StatusBar')) {
    await StatusBar.setStyle({ style: Style.Dark });
    await StatusBar.show();
  }

  if (window.plugins && window.plugins.OneSignal) {
    window.plugins.OneSignal.setLogLevel(6, 0);
    window.plugins.OneSignal.setAppId(process.env.VUE_APP_ONE_SIGNAL_APP_ID);

    window.plugins.OneSignal.setNotificationOpenedHandler((jsonData) => {
      console.log(`notificationOpenedCallback: ${JSON.stringify(jsonData)}`);
    });

    window.plugins.OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log(`User accepted notifications: ${accepted}`);
    });
  } else {
    console.warn('OneSignal is not available');
  }
}

document.addEventListener('deviceready', registerListeners, false);

let vInstance;

Vue.config.productionTip = false;
axios.defaults.withCredentials = process.env.NODE_ENV === 'production';

Vue.prototype.$http = axios.create({
  baseURL: config.apiUrl,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(`${config.appName}_token`)}`,
  },
});

Vue.component('ClipLoader', ClipLoader);

Vue.use(CripVueLoading, { axios: Vue.prototype.$http });

Vue.prototype.$http.interceptors.request.use(
  (axiosConfig) => {
    axiosConfig.headers.Authorization = `Bearer ${localStorage.getItem(`${config.appName}_token`)}`;
    return axiosConfig;
  },
  (error) => Promise.reject(error),
);

Vue.prototype.$http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      console.warn('error.response 401', error.response);
      if (
        error.response
        && error.response.config
        && error.response.config.url
        && (error.response.config.url.indexOf('/login') === -1
          || error.response.config.url.indexOf('/register') === -1)
      ) {
        console.warn('401 => Logging out the USER');
        store.dispatch('logout');
        if (
          vInstance.$router
          && vInstance.$route
          && vInstance.$route.path.indexOf('/register') === -1
          && vInstance.$route.path.indexOf('/reset-password') === -1
        ) {
          vInstance.$router.push('/welcome');
        }
      }
    }
    return Promise.reject(error);
  },
);

const i18n = new VueI18n({
  locale: store.state.locale || 'fr', // set locale
  messages: _.merge({ en }, store.state.translations || {}), // set locale messages
  silentTranslationWarn: process.env.VUE_APP_NO_I18N_LOGS || process.env.NODE_ENV === 'production',
});

/* eslint-disable no-new */

store.commit('extendedRoles');

async function initVue(models) {
  MatomoService.init();
  const routes = await import('./routes');
  // configure router
  const router = new VueRouter({
    base: '/',
    mode: window.cordova ? 'hash' : 'history',
    routes: routes.default,
    linkActiveClass: 'nav-item active',
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);
      return result ? `?${result}` : '';
    },
  });

  router.beforeEach((to, from, next) => {
    document.title = `${config.defaultTitle} - ${to.meta.title || to.name}`;
    next();
  });

  let user;
  if (localStorage.getItem(`${config.appName}_user`)) {
    user = localStorage.getItem(`${config.appName}_user`);

    try {
      user = JSON.parse(user);
      App.userId = user && user.id;
    } catch (err) {
      console.log(err.message);
    }
  }

  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://www.livodoc.fr',
    siteId: 1,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    trackerUrl: 'https://livodoc.fr/js/container_FOjMkICA.js',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: false,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Require consent before creating matomo session cookie
    // Default: false
    requireCookieConsent: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: true,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: user && user.id,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: [],

    // A function to determine whether to track an interaction as a site search
    // instead of as a page view. If not a function, all interactions will be
    // tracked as page views. Receives the new route as an argument, and
    // returns either an object of keyword, category (optional) and resultsCount
    // (optional) to track as a site search, or a falsey value to track as a page
    // view.
    // Default: false, i.e. track all interactions as page views
    // Example: (to) => {
    //   if (to.query.q && to.name === 'search') {
    //     return { keyword: to.query.q, category: to.params.category }
    //   } else {
    //    return null
    //   }
    // }
    trackSiteSearch: false,
  });

  vInstance = new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
    store,
    i18n,
    icons,
  });
  window.App.vue = vInstance;

  // REFRESH DATA
  if (localStorage.getItem(`${config.appName}_token`)) {
    vInstance.$store.dispatch('getProfile');
    vInstance.$store.dispatch('getPrograms');
    vInstance.$store.dispatch('getFavorites');
    vInstance.$store
      .dispatch('refreshUser')
      .then((res) => {
        vInstance.$store.commit('currentLocale', res.data.user.locale || config.defaultLocale);
        if (
          vInstance.$route.name === 'Login'
          && vInstance.$store.state.user
          && vInstance.$store.state.user[config.primaryKey]
        ) {
          setTimeout(() => {
            vInstance.$router.push('/app');
          }, 200);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

initVue();

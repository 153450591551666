import { uniq } from 'lodash';
import Vuex from 'vuex';
import Vue from 'vue';

import config from '@/config';
import localeFr from '@/config/locales/fr.json';
import localeEn from '@/config/locales/en.json';

Vue.use(Vuex);

const token = localStorage.getItem(`${config.appName}_token`);
const registrationStep = localStorage.getItem(`${config.appName}_registrationStep`);
const selectedProfileCode = localStorage.getItem(`${config.appName}_selectedProfileCode`);
const isOnboardingFinished = localStorage.getItem(`${config.appName}_isOnboardingFinished`);

let user;

try {
  user = localStorage.getItem(`${config.appName}_user`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_user`))
    : '';
} catch (err) {
  console.error(err);
}

function getExtendedRoles(role, state) {
  let myRoles = [];
  if (
    state.roles
    && state.roles[role]
    && state.roles[role].inherits
    && Array.isArray(state.roles[role].inherits)
  ) {
    myRoles = myRoles.concat(state.roles[role].inherits);
    state.roles[role].inherits.forEach(r => {
      myRoles = myRoles.concat(getExtendedRoles(r, state));
    });
  }
  return uniq(myRoles);
}

/*
const vuexLocal = new VuexPersistence({
  key: `vuex_${config.appName}`,
  storage: window.localStorage,
});

*/
const store = new Vuex.Store({
  //  plugins: [vuexLocal.plugin],
  programs: {},
  state: {
    user,
    token,
    translations: { fr: localeFr, en: localeEn },
    locale:
      (user && user.locale)
      || localStorage.getItem(`${config.appName}_locale`)
      || config.defaultLocale,
    roles: {
      USER: { inherits: [] }, // can access the app
      BO: { inherits: ['USER'] }, // can access the ADMIN
      DOCTOR: { inherits: ['BO'] },
      ADMIN: { inherits: ['DOCTOR'] },
      SUPERADMIN: { inherits: ['ADMIN'] },
    },
    userExtendedRoles: [],
    data: {
      resources: [],
      tasks: [],
    },
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    registrationStep,
    isOnboardingFinished,
    initialAssessmentQuizzes: [],
    programs: [],
    selectedProgram: null,
    selectedMediaTab: 'video',
    selectedSettingsTab: 'perso',
    mediaModalContent: null,
    profiles: [],
    selectedProfile: null,
    selectedProfileCode,
    favorites: [],
    studyCode: null,
    subscriptionProducts: [],
  },
  mutations: {
    resources(state, resources) {
      state.data.resources = resources;
    },

    tasks(state, tasks) {
      state.data.tasks = tasks;
    },

    roles(state, appRoles) {
      //  state.roles = appRoles;
    },

    extendedRoles(state) {
      let myRoles
        = state.user && state.user.roles && state.user.roles.isArray ? state.user.roles : [];
      myRoles.forEach(role => {
        myRoles = myRoles.concat(getExtendedRoles(role, state));
      });
      state.userExtendedRoles = myRoles;
    },

    user(state, currentUser) {
      state.user = currentUser;
      state.locale = currentUser.locale;
      localStorage.setItem(`${config.appName}_user`, JSON.stringify(currentUser));
    },

    currentLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem(`${config.appName}_locale`, locale);
    },

    token(state, appToken) {
      this._vm.$http.defaults.headers.common.Authorization = `Bearer ${appToken}`;
      this._vm.$http.defaults.headers.Authorization = `Bearer ${appToken}`;
      state.token = appToken;
      localStorage.setItem(`${config.appName}_token`, appToken);
    },

    registrationStep(state, step) {
      state.registrationStep = step;
      localStorage.setItem(`${config.appName}_registrationStep`, step);
    },

    isOnboardingFinished(state, value) {
      state.isOnboardingFinished = value;
      localStorage.setItem(`${config.appName}_isOnboardingFinished`, value);
    },

    commitData(state, key, value) {
      state.data[key] = value;
    },

    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : 'responsive';
    },

    toggleSidebarMobile(state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : 'responsive';
    },

    initialAssessmentQuizzes(state, list) {
      state.initialAssessmentQuizzes = (list || []).sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }

        if (a.order > b.order) {
          return 1;
        }

        return a.id < b.id ? -1 : 1;
      });
    },

    set(state, [variable, value]) {
      state[variable] = value;
    },

    programs(state, programs) {
      state.programs = programs;
    },

    selectedProgram(state, selectedProgram) {
      state.selectedProgram = selectedProgram;
    },

    subscriptionProducts(state, subscriptionProducts) {
      state.subscriptionProducts = subscriptionProducts;
    },

    selectedMediaTab(state, selectedMediaTab) {
      state.selectedMediaTab = selectedMediaTab;
    },

    selectedSettingsTab(state, selectedSettingsTab) {
      state.selectedSettingsTab = selectedSettingsTab;
    },

    mediaModalContent(state, mediaModalContent) {
      state.mediaModalContent = mediaModalContent;
    },

    profiles(state, profiles) {
      state.profiles = profiles;
    },

    studyCode(state, studyCode) {
      state.studyCode = studyCode;
    },

    selectedProfile(state, selectedProfile) {
      state.selectedProfile = selectedProfile;

      state.selectedProfileCode = selectedProfile && selectedProfile.code;
      localStorage.setItem(
        `${config.appName}_selectedProfileCode`,
        selectedProfile && selectedProfile.code,
      );
    },

    favorites(state, favorites) {
      state.favorites = favorites;
    },
  },
  getters: {},
  actions: {
    logout({ commit, state }) {
      delete App.user;
      commit('user', '');
      commit('token', '');
      commit('selectedProfile', null);
      localStorage.clear();
      return true;
    },

    user({ commit }, currentUser) {
      commit('user', currentUser);
      commit('extendedRoles');
      return true;
    },

    refreshUser({ commit, dispatch }) {
      const q = this._vm.$http.get('/api/auth/user');
      q.then(res => {
        App.user = res.data.user;
        localStorage.setItem(`${config.appName}_user`, JSON.stringify(res.data.user));
        commit('user', res.data.user);
        commit('extendedRoles');
      }).catch(err => {
        console.warn('refresh error', err);
        if (err.response && err.response.status === 404) {
          dispatch('logout');
        }
      });
      return q;
    },

    getTranslations(context) {
      const p = this._vm.$http.get(`/api/translations/${context.state.locale}`);

      p.then(res => {
        context.commit('translations', res.data);
      }).catch(err => {
        console.warn(err);
      });

      return p;
    },

    getInitialAssessmentData(context) {
      const p = this._vm.$http.get(`/api/quiz?isInitialAssessment=1&profileId=${context.state.selectedProfile && context.state.selectedProfile.id}`);
      p.then(res => {
        context.commit('initialAssessmentQuizzes', res.data && res.data.body ? res.data.body : []);
      }).catch(err => {
        console.warn(err);
      });

      return p;
    },

    changeLocale(context, locale) {
      context.commit('currentLocale', locale);
      // context.dispatch('getTranslations');
    },
    // eslint-disable-next-line
    init(context) {
      //  return context.dispatch('getTranslations');
    },

    async getPrograms(context) {
      const { data } = await this._vm.$http.get(
        `/api/profile_program?filters[profileId]=${context.state.user.profileId}`
      );
      if (data && data.body) {
        const profilePrograms = data.body;
        const programs = profilePrograms.map((item) => ({
          ...item.Program,
          isTrial: item.isTrial
        }));

        context.commit('programs', programs);
      }
    },

    async getSubscriptionProducts(context) {
      const { data } = await this._vm.$http.get(
        '/api/subscription_product?sort[price]=ASC'
      );

      if (data && data.body) {
        context.commit('subscriptionProducts', data.body);
      }
    },

    async getProfile(context) {
      const { data } = await this._vm.$http.get(`/api/profile/${context.state.user.profileId}`);
      if (data && data.body) {
        context.commit(
          'selectedProfile',
          data.body,
        );
      }
      return context.commit('profiles', data.body);
    },

    async selectProfile(context, profileCode = null) {
      let profile;
      const userProfileCode = context.state.user && context.state.user.profileId;

      if (profileCode || userProfileCode) {
        context.commit('selectedProfile', null);
        const { data } = await this._vm.$http.get(`/api/profile/${profileCode || userProfileCode}`);
        profile = data.body;
        context.commit('selectedProfile', profile);
      } else {
        await context.dispatch('getProfile');
        if (context.state.selectedProfile) {
          console.warn(context.state.selectedProfile);
          profile = context.state.selectedProfile;
        }
      }

      return profile;
    },

    async getFavorites(context) {
      const { data } = await this._vm.$http.get('/api/media/favorite');
      if (data && data.body) {
        context.commit('favorites', data.body);
      }
    },
  },
});

export default store;
